const APP_FALLBACK_LOCALE = 'en';
const APP_SUPPORTED_LOCALES = ['de', 'en', 'it', 'fr', 'es', 'hu'];

const isCloudflarePages = !!process.env.REACT_APP_CF_PAGES_BRANCH;

// we define the environment name to match the branch name. It's typically dev, demo, prod or the name of the branch (e.g. feat/cool-feature)
const env =
  process.env.REACT_APP_CF_PAGES_BRANCH || process.env.REACT_APP_ENV || 'dev';

// the env tag is used to get the correct ENV var below
const envTag = ['prod', 'demo', 'staging', 'dev'].includes(env)
  ? env.toUpperCase()
  : 'DEV'; // for all other envs (e.g. feat/cool-feature), they share some vars with the dev environment

// build the (base) config, which may be partially overridden below
const config = {
  env,
  stripePK: process.env.REACT_APP_STRIPE_API_PUBLIC_KEY, // same for demo + staging + dev
  apiBaseUrl: process.env[`REACT_APP_${envTag}_API_BASE_URL`],
  wsBaseUrl: process.env[`REACT_APP_${envTag}_WS_BASE_URL`],
  shortUrl: process.env[`REACT_APP_${envTag}_SHORT_URL`], // e.g. menu.sb short url
  commitSha: process.env.REACT_APP_COMMIT_SHA,
  version: process.env.REACT_APP_VERSION,
  auth0: {
    domain: process.env[`REACT_APP_${envTag}_AUTH0_DOMAIN`],
    clientId: process.env[`REACT_APP_${envTag}_AUTH0_CLIENT_ID`],
    audience: process.env[`REACT_APP_${envTag}_AUTH0_AUDIENCE`],
  },
  cfImages: {
    accountHash: process.env.REACT_APP_CF_IMAGES_ACCOUNT_HASH,
    baseUrl: `${window.location.origin}/cdn-cgi/imagedelivery/${process.env.REACT_APP_CF_IMAGES_ACCOUNT_HASH}`,
  },
  sentry: { dsn: process.env.REACT_APP_SENTRY_DSN },
  google: { apiKey: process.env.REACT_APP_GOOGLE_API_KEY }, // same for all environments, it's restricted on Google's side
};

// adjustments for branch based deployments
if (/^[a-z]{3,}\/[a-z0-9\-]+$/.test(env) && isCloudflarePages) {
  // build the branch based backend URL
  const sanitizedBranchName = env.replace('/', '-');
  const apiHostname = `${sanitizedBranchName}.dev.api.servier.bar`;

  config.apiBaseUrl = `https://${apiHostname}`;
  config.wsBaseUrl = `wss://${apiHostname}`;
  config.shortUrl = null; // disable the short URL, it is not available here
}

// adjustments for local development
if (config.env === 'dev' && !isCloudflarePages) {
  // the /cdn-cgi/... URL works only on Cloudflare, use this to have access from anywhere
  config.cfImages.baseUrl = `https://imagedelivery.net/${config.cfImages.accountHash}`;
  config.shortUrl = null; // disable the short URL, it is not available here
}

export {
  APP_FALLBACK_LOCALE,
  APP_SUPPORTED_LOCALES,
  isCloudflarePages,
  config,
};
